export enum COLUMNS {
  '#' = 'index',
  NAME = 'fullName',
  EMAIL = 'email',
  ROLE = 'role',
  BIO = 'bio',
  HEALTHIE_ID = 'getHealthieId',
  MANAGER = 'manager',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SCHEDULING_PRIORITY = 'schedulingPriority',
  STATE_LICENCES = 'stateLicences',
  ACTIVE = 'active',
  UPDATED_AT = 'updatedAt',
  PRIORITY_CHANGED_AT = 'priorityChangedAt',
  CREDENTIALS = 'credentials',
  PREMIUM = 'premium',
  OUT_OF_OFFICE = 'outOfOffice'
}
