import { useAuthorizedFeature } from 'hooks'
import { useCurrentAdminQuery, HealthPortalFeature, useCurrentUserQuery } from 'types'

export const useCanEditCoachProfile = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()
  const [isAuthorizedToEditCoachProfile, isAuthorizedToEditOwnCoachProfile] = useAuthorizedFeature([
    HealthPortalFeature.UsersEditCoachProfile,
    HealthPortalFeature.UsersEditOwnCoachProfile
  ])

  const isOwnProfile = currentAdmin?.id === currentUser?.id

  if (isOwnProfile) {
    return isAuthorizedToEditOwnCoachProfile
  }

  return isAuthorizedToEditCoachProfile
}
