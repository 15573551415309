import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import SettingsIcon from '@mui/icons-material/Settings'
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone'
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined'
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import { Avatar, Box, Card, IconButton, Stack, Tooltip } from '@mui/material'
import { ACCOUNT_REQUEST_TITLE_MAP } from 'accountRequest.types'
import { AuthorizedFeature, WithCopyToClipboardAction } from 'components'
import { AccountRequestsMenu } from 'components/AccountRequestsMenu'
import { env } from 'config'
import {
  dateRangeDropDownQueryParamsVar,
  planDetailsDialogVisibleVar,
  userSettingsDialogVisibleVar
} from 'context'
import { FeatureFlags } from 'featureFlags.types'
import { useAuthorizedFeature, useFeatureFlag } from 'hooks'
import CustomEventTypes from 'hooks/analytics/customEventTypes'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { LocationPaths } from 'location.types'
import { startCase } from 'lodash'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { latestSurveyOfKind } from 'screens/Questionnaire/helpers/latestSurveyOfKind'
import { ChipLabel, ChipStyled } from 'styles'
import { theme } from 'theme'
import { UserResource } from 'userResource.types'
import { nameInitials } from 'utility/nameInitials'
import {
  BillingProduct,
  CoachAssignmentState,
  HealthPortalFeature,
  ISurveyLinkCollection,
  SurveysConfigKind,
  useCurrentUserQuery,
  useNextUserAppointmentLazyQuery,
  UserReferralPartner,
  useUserSurveyLinksQuery
} from 'types'
import { PlansDetails } from './PlansDetails'
import {
  CardHeaderStyled,
  ItemStyled,
  PartnerLogoStyled,
  StackWithIcons,
  SubtitleStyled,
  TitleStyled
} from './styles'

const UNKNOWN = 'Unknown'

const SEX_ICON_LOOKUP = {
  Male: MaleOutlinedIcon,
  Female: FemaleOutlinedIcon,
  [UNKNOWN]: TransgenderOutlinedIcon
}

const occupation = (userSurveyLinks: ISurveyLinkCollection | undefined) => {
  const latestGoalsSurvey = latestSurveyOfKind(
    SurveysConfigKind.Goals,
    userSurveyLinks?.surveyLinks
  )

  const occupationAnswer = latestGoalsSurvey?.responses?.find(
    (response) => response.questionKey === 'occupation'
  )?.answer?.value

  return occupationAnswer || UNKNOWN
}

const formattedDate = (date: string) => {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  })
}

const PREMIUM_REFERRAL_PARTNERS = [UserReferralPartner.Influencer, UserReferralPartner.Vip]

interface IMemberBioProps {
  showOpenDashboardIcon?: boolean
  showOpenChatIcon?: boolean
}

export const MEMBER_BIO_HEIGHT = 140

export const MemberBio = ({ showOpenChatIcon, showOpenDashboardIcon }: IMemberBioProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { userSurveyLinks } = {} } = useUserSurveyLinksQuery()
  const [
    getNextUserAppointment,
    { data: { nextUserAppointment } = { nextUserAppointment: null } }
  ] = useNextUserAppointmentLazyQuery()
  const mobileAppPresentationModeEnabled = useFeatureFlag(FeatureFlags.MobileAppPresentationMode)
  const nutritionistHomeEnabled = useFeatureFlag(FeatureFlags.NutritionistHome)

  let dateRangeDropDownQueryParams = useReactiveVar(dateRangeDropDownQueryParamsVar)
  dateRangeDropDownQueryParams = dateRangeDropDownQueryParams
    ? `?${dateRangeDropDownQueryParams}`
    : ''

  const navigate = useNavigate()
  const { track } = useSegmentInteraction()

  const nutritionistHomeAuthorized = useAuthorizedFeature(HealthPortalFeature.NutritionistHome)

  useEffect(() => {
    if (currentUser && nutritionistHomeAuthorized)
      getNextUserAppointment({ variables: { id: currentUser.id } })
  }, [currentUser, nutritionistHomeAuthorized, getNextUserAppointment])

  const lastVideoChatAssignment = currentUser?.lastVideoChatAssignment
  const ongoingDietitianSubscription = currentUser?.ongoingDietitianSubscription
  const hasPaidAddon =
    ongoingDietitianSubscription?.primaryProduct.key === BillingProduct.NutrisenseDietitian
  const medicalConsultAddon = currentUser?.ongoingSubscriptions?.find((subscription) => {
    return subscription.primaryProduct.key === BillingProduct.MedicalConsultAddon
  })
  const hasOngoingVideoChat =
    lastVideoChatAssignment && lastVideoChatAssignment.state !== CoachAssignmentState.Inactive
  const endsAt = ongoingDietitianSubscription?.cancelAt
  const address = currentUser?.address
  const sex = currentUser?.sex || UNKNOWN
  const SexIcon = SEX_ICON_LOOKUP[sex as keyof typeof SEX_ICON_LOOKUP]
  const referralPartner = currentUser?.referralPartner

  const openMobileAppVideoShare = () => {
    track(CustomEventTypes.StartPresentationMode, { memberId: currentUser?.id })
    window.open(`/presentation?userEmail=${encodeURIComponent(currentUser?.email || '')}`, '_blank')
  }

  const openHealthieProfile = () => {
    window.open(`${env.REACT_APP_HEALTHIE_URL}/users/${currentUser?.healthieId}`, '_blank')
  }

  return (
    <Card sx={{ height: `${MEMBER_BIO_HEIGHT}px` }}>
      <CardHeaderStyled
        avatar={
          <Avatar src={currentUser?.avatar || ''}>{nameInitials(currentUser?.fullName)}</Avatar>
        }
        action={
          <>
            {nutritionistHomeEnabled && (
              <AuthorizedFeature feature={HealthPortalFeature.NutritionistHome}>
                <Tooltip
                  title={`${currentUser?.healthieId ? '' : 'No '}Healthie Account`}
                  placement="top"
                >
                  <IconButton
                    aria-label="healthie-account"
                    onClick={openHealthieProfile}
                    disabled={!currentUser?.healthieId}
                  >
                    <AccountCircleOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </AuthorizedFeature>
            )}
            {mobileAppPresentationModeEnabled && (
              <AuthorizedFeature feature={HealthPortalFeature.HomeMobileAppPresentationMode}>
                <Tooltip title="Mobile App Presentation Mode" placement="top">
                  <IconButton
                    aria-label="mobile-app-presentation-mode"
                    onClick={openMobileAppVideoShare}
                  >
                    <PhoneIphoneIcon />
                  </IconButton>
                </Tooltip>
              </AuthorizedFeature>
            )}
            <AuthorizedFeature feature={HealthPortalFeature.SupportAccountRequestsCreate}>
              <Tooltip title="Show account request actions" placement="top">
                <Box component="span">
                  <AccountRequestsMenu accountRequestTitleMap={ACCOUNT_REQUEST_TITLE_MAP}>
                    <IconButton aria-label="customer-support">
                      <ViewKanbanOutlinedIcon />
                    </IconButton>
                  </AccountRequestsMenu>
                </Box>
              </Tooltip>
            </AuthorizedFeature>
            {showOpenDashboardIcon && (
              <Tooltip title="Open Dashboard" placement="top">
                <IconButton
                  data-testid="dashboard-link"
                  aria-label="dashboard-link"
                  onClick={() =>
                    navigate(
                      `${LocationPaths.Users}/${currentUser?.id}/${UserResource.Root}${dateRangeDropDownQueryParams}`
                    )
                  }
                >
                  <OpenInNewOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {showOpenChatIcon && (
              <Tooltip title="Open Chat" placement="top">
                <IconButton
                  data-testid="chat-link"
                  aria-label="chat-link"
                  onClick={() =>
                    navigate(`${LocationPaths.Users}/${currentUser?.id}/${UserResource.Chat}`)
                  }
                >
                  <MessageOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Show plan details" placement="top">
              <IconButton
                aria-label="plan-details"
                onClick={() => planDetailsDialogVisibleVar(true)}
              >
                <ListAltOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Display all member settings" placement="top">
              <IconButton aria-label="settings" onClick={() => userSettingsDialogVisibleVar(true)}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        title={
          <Stack>
            <Stack direction="row" alignItems="flex-start" gap={2}>
              <Stack gap={0.5}>
                <TitleStyled>
                  <ReactRouterLink
                    to={`${LocationPaths.Users}/${currentUser?.id}`}
                    target="_blank"
                    style={{ color: theme.palette['text-color'].primary, textDecoration: 'none' }}
                  >
                    {currentUser?.fullName}
                  </ReactRouterLink>
                </TitleStyled>
                <WithCopyToClipboardAction value={currentUser?.email}>
                  <SubtitleStyled>{currentUser?.email}</SubtitleStyled>
                </WithCopyToClipboardAction>
              </Stack>
              <Stack gap={1}>
                <Stack direction="row" alignItems="center" gap={2}>
                  {referralPartner?.logoUrl && referralPartner?.requiresProtocol && (
                    <ChipStyled
                      variant="filled"
                      label={
                        <ChipLabel>
                          <PartnerLogoStyled src={referralPartner.logoUrl} /> member
                        </ChipLabel>
                      }
                      size="small"
                    />
                  )}
                  {currentUser?.ongoingCoreSubscription && (
                    <ChipStyled
                      label={
                        <ChipLabel>
                          {currentUser.ongoingCoreSubscription.primaryProduct.title}
                        </ChipLabel>
                      }
                      size="small"
                    />
                  )}
                  {medicalConsultAddon && (
                    <ChipStyled
                      label={<ChipLabel>{medicalConsultAddon.primaryProduct.title}</ChipLabel>}
                      size="small"
                    />
                  )}
                  {hasPaidAddon && (
                    <ChipStyled label={<ChipLabel>Paid Add-On</ChipLabel>} size="small" />
                  )}
                  {endsAt && hasPaidAddon && !hasOngoingVideoChat && (
                    <ChipStyled
                      label={<ChipLabel>Ends {formattedDate(endsAt)}</ChipLabel>}
                      size="small"
                    />
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                  {referralPartner?.key &&
                    PREMIUM_REFERRAL_PARTNERS.includes(referralPartner.key) && (
                      <ChipStyled
                        label={<ChipLabel>{referralPartner.key}</ChipLabel>}
                        size="small"
                        color={
                          referralPartner.key === UserReferralPartner.Influencer
                            ? 'info'
                            : 'warning'
                        }
                      />
                    )}
                  {hasOngoingVideoChat && (
                    <>
                      <ChipStyled label={<ChipLabel>Video Call</ChipLabel>} size="small" />
                      <ChipStyled
                        label={<ChipLabel>{lastVideoChatAssignment.state}</ChipLabel>}
                        size="small"
                      />
                    </>
                  )}
                  {nextUserAppointment?.meetingAt && (
                    <ChipStyled
                      label={
                        <ChipLabel>Next {formattedDate(nextUserAppointment.meetingAt)}</ChipLabel>
                      }
                      size="small"
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <StackWithIcons direction="row" alignItems="center" gap={0.5}>
              <SexIcon />
              <ItemStyled>{sex}</ItemStyled>
              <CalendarTodayOutlinedIcon />
              <ItemStyled>{currentUser?.age ? currentUser.age + ' y/o' : UNKNOWN}</ItemStyled>
              <WorkOutlineOutlinedIcon />
              <ItemStyled>{occupation(userSurveyLinks as ISurveyLinkCollection)}</ItemStyled>
            </StackWithIcons>
            <StackWithIcons direction="row" alignItems="center" gap={0.5}>
              <LocationOnOutlinedIcon />
              <ItemStyled>{address ? address.city + ', ' + address.state : UNKNOWN}</ItemStyled>
              <AuthorizedFeature feature={HealthPortalFeature.UsersViewFulfillmentConfiguration}>
                <Tooltip title="The preferred sensor category to send this user. This may differ from the sensor the user actually has.">
                  <SpeakerPhoneIcon />
                </Tooltip>
                <ItemStyled>
                  Active Sensor: {startCase(currentUser?.sensor?.model) || 'None'}
                </ItemStyled>
              </AuthorizedFeature>
              <AuthorizedFeature feature={HealthPortalFeature.DexcomResearchViewStatus}>
                <ItemStyled>
                  Research Status:{' '}
                  {startCase(currentUser?.lastDexcomResearchState?.status) || 'None'}
                </ItemStyled>
              </AuthorizedFeature>
            </StackWithIcons>
          </Stack>
        }
      />
      <PlansDetails />
    </Card>
  )
}
