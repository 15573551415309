import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { IModalProps } from 'hooks'
import { Controller, useForm } from 'react-hook-form'
import { AddressState, useCurrentUserQuery, useUpsertCoachProfileMutation } from 'types'

interface IEditCoachProfileForm {
  stateLicences: string[]
  outOfOffice: boolean
  premium: boolean
}

const options = Object.values(AddressState).map((value) => value.toString())

export const EditCoachProfileDialog = ({ closeModal, modalState }: IModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const { handleSubmit, control, setValue } = useForm<IEditCoachProfileForm>({
    mode: 'onTouched',
    defaultValues: {
      stateLicences: currentUser?.coachProfile?.stateLicences || [],
      outOfOffice: currentUser?.coachProfile?.outOfOffice || false,
      premium: currentUser?.coachProfile?.premium || false
    }
  })

  const [upsertCoachProfile, { loading: upsertCoachProfileLoading }] =
    useUpsertCoachProfileMutation({
      onError: (error) =>
        snackBarVisibleVar({
          open: true,
          message: error.message
        }),
      refetchQueries: ['currentUser'],
      onCompleted: closeModal
    })

  if (!currentUser) {
    return null
  }

  const upsertCoachProfileHandler = async (formData: IEditCoachProfileForm) => {
    await upsertCoachProfile({ variables: formData })
  }

  return (
    <Dialog open={modalState} onClose={closeModal}>
      <DialogTitle>Edit Coach Profile</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(upsertCoachProfileHandler)}>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="stateLicences"
              render={({ field: { value } }) => {
                return (
                  <Autocomplete
                    multiple
                    options={options}
                    value={value}
                    onChange={(_event, newValue) => {
                      setValue('stateLicences', newValue)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="States" placeholder="States" />
                    )}
                  />
                )
              }}
            />
          </FormControl>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="outOfOffice"
              render={({ field: { value } }) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">Out of Office</Typography>
                    <Switch
                      checked={value}
                      value={value}
                      onChange={(_event, newValue) => {
                        setValue('outOfOffice', newValue)
                      }}
                    />
                  </Box>
                )
              }}
            />
          </FormControl>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="premium"
              render={({ field: { value } }) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">Premium</Typography>
                    <Switch
                      checked={value}
                      value={value}
                      onChange={(_event, newValue) => {
                        setValue('premium', newValue)
                      }}
                    />
                  </Box>
                )
              }}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <LoadingButton
          loading={upsertCoachProfileLoading}
          onClick={handleSubmit(upsertCoachProfileHandler)}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
