import {
  UserDynamicFilterField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  UserSortField,
  SortDirection,
  UserRole
} from 'types'

export const getAllCoachFilters = () => {
  return {
    dynamicFilters: [
      {
        items: [
          {
            field: UserDynamicFilterField.Role,
            operation: DynamicFilterItemOperation.In,
            value: [UserRole.Nutritionist, UserRole.NutritionistManager].join(',')
          }
        ],
        operator: DynamicFilterOperator.And
      }
    ],
    sorts: [
      {
        direction: SortDirection.Desc as SortDirection,
        field: UserSortField.CoachAssignmentInfoActiveAssignmentsCount
      }
    ]
  }
}
